<template>
  <validation-observer ref="userRules">
    <b-card>
      <b-row class="mb-2">
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col">General Information</th>
            </tr>
          </thead>
        </table>
      </b-row>

      <profile-cropper v-if="selectedImage" :image="selectedImage" @cropped="imageCropped" />

      <!-- image upload -->
      <b-row>
        <b-col xs="12" sm="6" md="3" lg="2">
          <b-img-lazy thumbnail fluid v-bind="mainProps" style="width: 100%" ref="refPreviewEl" :src="imageUrl" />

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" @click="$refs.refInputEl.click()" block variant="primary">
            Upload
          </b-button>
        </b-col>

        <!-- <b-form-file ref="refInputEl" class="d-none" accept="image/*" @input="imageSelected" v-model="tempImage" drop-placeholder="Drop file here..." /> -->

        <input ref="refInputEl" type="file" class="d-none" accept="image/*" @input="imageSelected" />

        <b-col v-if="percentCompleted != 0 && percentCompleted != 100" class="mt-2">
          <b-progress key="info" animated :value="percentCompleted" show-progress variant="dark" class="'progress-bar-info'" />
        </b-col>
      </b-row>

      <!-- image upload -->

      <!-- form -->
      <b-row class="mt-2">
        <b-col sm="6">
          <validation-provider #default="{ errors }" name="Name" rules="required">
            <b-form-group label="Name" label-for="account-name">
              <b-form-input :disabled="true" v-model="userLocal.name" placeholder="Name" name="name" />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col sm="6">
          <validation-provider #default="{ errors }" name="Surname" rules="required">
            <b-form-group label="Surname" label-for="surname">
              <b-form-input :disabled="true" name="surname" placeholder="Surname" v-model="userLocal.surname" />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col sm="6">
          <validation-provider #default="{ errors }" name="E-mail" rules="required">
            <b-form-group label="E-mail" label-for="account-e-mail">
              <b-form-input :disabled="true" v-model="userLocal.email" name="email" placeholder="Email" />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col sm="6">
          <b-form-group label="User ID" label-for="User ID">
            <b-form-input :disabled="true" v-model="userLocal.id" name="id" placeholder="User ID" />
          </b-form-group>
        </b-col>

        <!-- <b-col cols="12" class="mr-1 float-right">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-2 mr-1 float-right" @click="validationForm">
            Save changes
          </b-button>
        </b-col> -->
      </b-row>
    </b-card>
  </validation-observer>
</template>

<script>
import { BProgress, BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils';
import { ref } from '@vue/composition-api';
import store from '@/store';
import formValidation from '@core/comp-functions/forms/form-validation';
import { required, alphaNum, email } from '@validations';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ProfileCropper from '@core/components/image-cropper/ProfileCropper.vue';
import axiosIns from '@/libs/axios';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { mapState, mapGetters } from "vuex";

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    ValidationProvider,
    ValidationObserver,
    BProgress,
    ProfileCropper,
  },
  directives: {
    Ripple,
  },
  data() {
    const { refFormObserver, getValidationState } = formValidation();
    return {
      imageUrl: store.state.app.profileURL + 'none.jpg',
      tempImage: null,
      profileURL: store.state.app.profileURL,
      selectedImage: null,
      croppedImage: null,
      userData: {},
      percentCompleted: 0,
      userLocal: {
        name: JSON.parse(localStorage.getItem('userData')).name,
        surname: JSON.parse(localStorage.getItem('userData')).surname,
        email: JSON.parse(localStorage.getItem('userData')).email,
        id: JSON.parse(localStorage.getItem('userData')).id,
        role: '',
        status: '',

        image: '',
        profile: '',
      },
      mainProps: {
        blank: true,
        blankColor: '#777',
        width: 75,
        height: 75,
        class: 'm1',
      },
      required,
      alphaNum,
      email,
      profileFile: null,
    };
  },
  mounted() {
   
  },
  computed: {
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
    ...mapGetters([
      'authUser',
    ]),
  },
  methods: {
    imageUpload() {},
    imageSelected(event) {
      const file = event.target.files ? event.target.files[0] : null;
      if (file != null) {
        this.getBase64(file).then((data) => {
          this.selectedImage = data;
        });
      }
    },
    imageCropped(croppedImage) {
      if (croppedImage != null) {
        this.$emit('update:formShow', true);
        let formData = new FormData();
        formData.append('file', croppedImage);
        const config = {
          onUploadProgress: function(progressEvent) {
            this.percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          }.bind(this),
        };
        formData.append('id', this.userLocal.id);

        axiosIns
          .post('cropProfilePhoto', formData, config)
          .then((response) => {
            this.userLocal.profile = response.data;
            this.imageUrl = this.profileURL + response.data;

            this.tempImage = null;
            this.selectedImage = null;
            this.$emit('update:formShow', false);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Upload Successful',
                text: '✔️ Image Cropped Successfully',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });
          })
          .catch((error) => {
            console.log(error);
            this.$emit('update:formShow', false);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Upload Not Successful',
                text: '⛔ Image Upload Not Successful',
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            });
          });
      } else {
        this.tempImage = null;
        this.selectedImage = null;
      }
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    updateUser() {
      this.$emit('update:formShow', true);
      store
        .dispatch('user/updateUser', this.userLocal)
        .then((response) => {
          if (response.status == 200) {
            this.$swal({
              title: 'Success!',
              text: 'Info has been updated!',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-success',
              },
              buttonsStyling: false,
            });
          }
          this.$emit('update:formShow', false);
        })
        .catch((error) => {
          this.$emit('update:formShow', false);
          this.$swal({
            title: 'Error!',
            text: 'User Could not be updated',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          });

          console.log(error);
        });
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.userRules.validate().then((success) => {
          if (success) {
            this.updateUser();
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Christ no!',
                text: '📝 Required fields cannot be left blank',
                icon: 'AlertTriangleIcon',
                variant: 'warning',
              },
            });
            //    reject();
          }
        });
      });
    },
  },
  created() {
    this.$emit('update:formShow', false);
    // var userId = JSON.parse(localStorage.getItem('userData')).id;

    // store
    //   .dispatch('user/fetchUser', { id: userId })
    //   .then((response) => {
    //     this.userLocal = {
    //       name: response.data.name,
    //       surname: response.data.surname,
    //       email: response.data.email,
    //       id: response.data.id,
    //       role: response.data.role,
    //       status: response.data.status,

    //       profile: response.data.profile,
    //     };
    //     this.imageUrl = this.profileURL + response.data.profile;

    //     this.$emit('update:formShow', false);
    //   })
    //   .catch((error) => {
    //     this.$emit('update:formShow', false);
    //     this.$swal({
    //       title: 'Error!',
    //       text: 'Password could not be changed',
    //       icon: 'error',
    //       customClass: {
    //         confirmButton: 'btn btn-primary',
    //       },
    //       buttonsStyling: false,
    //     });
    //     console.log(error);
    //   });
  },
  setup() {
    const refInputEl = ref(null);
    const previewEl = ref(null);
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl);
    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    };
  },

  props: {
    formShow: {
      required: false,
    },
  },
};
</script>

<style scoped>
.uploadButton {
  position: absolute;
  top: -8px;
  right: 85px;
  margin: 2px;
  padding: 3px;
  border-radius: 6px;
  color: #fff;
  background-color: #ff0000;
  cursor: pointer;
  opacity: 0.5;
}
.uploadButton:hover {
  opacity: 1;
}
.btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file input[type='file'] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}
</style>
